import { Slot } from 'primitives/slot'
import * as React from 'react'
import { tw } from 'utils/classnames'

type Variants = 'fill' | 'dark' | 'gray' | 'outline' | 'invisible' | 'danger'

type Sizes = 'small' | 'medium'
type Gestures = 'scale' | 'none'

interface ButtonProps {
  variant?: Variants
  size?: Sizes
  gesture?: Gestures
  asChild?: boolean
}

function ButtonRoot(
  {
    size = 'medium',
    gesture = 'none',
    variant = 'fill',
    asChild,
    ...props
  }: React.ComponentProps<'button'> & ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const className = tw(
    'rounded-2 text-14 outline-none',
    props.disabled && 'cursor-not-allowed',
    buttonClasses.sizes[size],
    buttonClasses.variants[variant],
    props.className,
  )

  const gestures = getGestureProps(gesture)

  const Comp = asChild ? Slot : 'button'

  return <Comp {...gestures} {...props} className={className} ref={ref} />
}

export function ButtonIcon(props: React.ComponentProps<'svg'>) {
  const Comp = Slot as unknown as 'svg'

  return <Comp {...props} className={tw('h-[24px] w-[24px]', props.className)} />
}

export const Button = Object.assign(React.forwardRef(ButtonRoot), {
  Icon: ButtonIcon,
})

const buttonClasses = {
  variants: {
    fill: [
      'text-service-white-white bg-violet-500',
      'hover:bg-[#6844C9]',
      'active:bg-[#552DC1] active:text-service-white-white focus:bg-violet-500 focus:text-service-white-white',
      'disabled:bg-gray-700 disabled:text-gray-400 disabled:hover:bg-gray-700 disabled:hover:text-gray-400',
    ].join(' '),
    invisible: [
      'bg-transparent',
      'hover:text-violet-500',
      'active:text-[#552DC1] focus:text-[#552DC1]',
      'disabled:bg-transparent disabled:text-gray-400-400 disabled:hover:bg-transparent disabled:hover:text-gray-400-400',
    ].join(' '),
    dark: [
      'bg-gray-900 text-gray-50',
      'hover:bg-[#6844C9] hover:text-service-white-white',
      'active:bg-[#552DC1] active:text-service-white-white focus:bg-[#552DC1] focus:text-service-white-white',
      'disabled:bg-gray-700 disabled:text-gray-400 disabled:hover:bg-gray-700 disabled:hover:text-gray-400',
    ].join(' '),
    gray: [
      'bg-gray-500-300 text-gray-50',
      'hover:text-service-white-white hover:bg-[#6844C9]',
      'active:text-service-white-white active:bg-[#552DC1] focus:text-service-white-white focus:bg-[#552DC1]',
      'disabled:bg-gray-700 disabled:text-gray-400 disabled:hover:bg-gray-700 disabled:hover:text-gray-400',
    ].join(' '),
    outline: [
      'bg-transparent text-gray-50 border border-gray-700 text-gray-50',
      'hover:border-violet-500 hover:text-violet-500 active:text-[#552DC1]',
      'active:border-[#552DC1] focus:border-[#552DC1]',
      'disabled:bg-transparent disabled:text-gray-400-400 disabled:border-gray-400-400',
    ].join(' '),
    danger: [
      'text-service-white-white bg-red-100',
      'hover:bg-[#AF1133]',
      'active:bg-[#990827] focus:bg-[#990827]',
      'disabled:bg-gray-700 disabled:text-gray-400 disabled:hover:bg-gray-700 disabled:hover:text-gray-400',
    ].join(' '),
  },
  sizes: {
    small: 'h-[34px] flex items-center px-[16px]',
    medium: 'h-[42px] flex items-center px-[16px]',
  },
}

function getGestureProps(gesture?: Gestures) {
  if (gesture === 'scale') {
    return {
      whileHover: {
        scale: 1.1,
      },
      whileTap: {
        scale: 1.05,
      },
    }
  }

  return {}
}
